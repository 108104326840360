// 部署环境配置项

const globalPath = {
  jsonPath: './',
  serverUrl: 'http://47.97.119.72:12345', // 公司官网
  imgPathWater: 'http://aqhb.ynhlxy.com:194/fileupload', // 图片资源前缀
  imgPath: 'http://aqhb.ynhlxy.com:194/fileupload', // 图片资源前缀
};

module.exports = globalPath;
